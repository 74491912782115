import React from 'react';
import { HashRouter, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import logo from './logo.svg';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Login = React.lazy(() => import('./views/authentication/login'));
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));

const App = () => {
  return (
    <HashRouter>
      <Router>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" component={Login} />
            {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
            <Route path="/" render={props => <DefaultLayout/>}/>
            
          </Switch>
        </React.Suspense>
      </Router>
    </HashRouter>
  );
}

export default App;
